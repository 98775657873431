import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Logo from '../components/logo';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import thankYouGif from '../assets/thank-you-gif-02.gif';
import styles from './referral.module.css';

const ReferralPage = () => {
  const url = 'https://bit.ly/34gbhCW',
    iconSize = 64,
    body = `Hey! Check out StockSolo, it's a free plugin that allows you to download free stock photos directly inside Adobe CC applications. `;

  return (
    <Layout>
      <SEO title="Help us spread the word!"/>

      {/* Logo */}
      <Logo/>

      {/* Spread the word! */}
      <div
        className={'p-4 lg:p-20 text-gray-300 w-full bg-gray-800 lg:p-10 rounded-lg'}>
        <h2>
          Tell a friend!
        </h2>
        <div>
          StockSolo is a free product and we intend to keep it that way, but
          please consider sharing it with your friends and colleagues.
          <br/>
          <br/>
          We put weeks of work into this project and it would mean a lot to us!
          Thanks.

          <div className={styles.installationsTitle}>
            Share StockSolo with your friends and colleagues
          </div>

          <div className={styles.shareIcons}>
            <div className={`${styles.iconContainer} sm:w-1/2`}>
              <EmailShareButton url={url} subject={'StockSolo'} body={body}>
                <EmailIcon size={iconSize} round={true}/>
                <div className={styles.label}>
                  Email
                </div>
              </EmailShareButton>
            </div>

            <div className={`${styles.iconContainer} sm:w-1/2`}>
              <TwitterShareButton url={url} title={body}>
                <TwitterIcon size={iconSize} round={true}/>
                <div className={styles.label}>
                  Twitter
                </div>
              </TwitterShareButton>
            </div>

            <div className={`${styles.iconContainer} sm:w-1/2`}>
              <FacebookShareButton url={url} quote={body}>
                <FacebookIcon size={iconSize} round={true}/>
                <div className={styles.label}>
                  Facebook
                </div>
              </FacebookShareButton>
            </div>

            <div className={`${styles.iconContainer} sm:w-1/2`}>
              <LinkedinShareButton url={url} title={'StockSolo'} summary={body}>
                <LinkedinIcon size={iconSize} round={true}/>
                <div className={styles.label}>
                  LinkedIn
                </div>
              </LinkedinShareButton>
            </div>

            <div className={`${styles.iconContainer} sm:w-1/2`}>
              <WhatsappShareButton url={url} title={body}>
                <WhatsappIcon size={iconSize} round={true}/>
                <div className={styles.label}>
                  Whatsapp
                </div>
              </WhatsappShareButton>
            </div>
          </div>

          <div className={styles.installationsTitle}>
            Thank you for sharing!
          </div>

          <img className={'py-8 mx-auto text-center'} src={thankYouGif}/>

          {/*<div className={styles.installationsTitle}>*/}
          {/*  People that installed StockSolo so far*/}
          {/*</div>*/}
          {/*<div className={styles.installationsCount}>*/}
          {/*  5,705*/}
          {/*</div>*/}

        </div>
      </div>
    </Layout>
  );
};

export default ReferralPage;
